import _ from 'lodash';
import forEach from 'lodash/forEach';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Markdown from 'markdown-to-jsx';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import Head from 'components/Head';
import MaxWidthContainer from 'components/MaxWidthContainer';
import Menubar from 'components/Menubar';
import MobileTabBar from 'components/MobileTabBar';
import IndexBannerCarouselMobile from 'components/IndexBannerCarouselMobile';
import IndexCommentCarouselMobile from 'components/IndexCommentCarouselMobile';
import CommentPopup from 'components/CommentPopup';
import Footer from 'components/Footer';

import api from 'utils/api';
// import getInitProps from 'utils/getInitProps';
import { httpCall } from 'functions/httpAction';
import { fetchAppLaunch } from 'functions/appLaunch';
import { MixpanelConfig } from 'utils/mixpanelConfig';

export default function Home() {
  const [arrNotificationState, setArrNotificationState] = useState(null);
  const [arrStoresState, setArrStoresState] = useState(null);
  const [arrProductOriginsState, setArrProductOriginsState] = useState(null);
  const [arrCategoryState, setArrCategoryState] = useState([]);
  const [arrSearchSkinProblemState, setArrSearchSkinProblemState] = useState(
    []
  );
  const [arrFactorTypesState, setArrFactorTypesState] = useState([]);
  const [arrBannerState, setArrBannerState] = useState([]);
  const [arrCommentState, setArrCommentState] = useState([]);
  const [arrReviewTagsState, setArrReviewTagsState] = useState([]);
  const [arrReviewSourcesState, setArrReviewSourcesState] = useState([]);

  const [searchBackgroundDisplayState, setSearchBackgroundDisplayState] =
    useState(false);
  const [searchProductInputFocusState, setSearchProductInputFocusState] =
    useState(false);
  const [searchCategoryInputFocusState, setSearchCategoryInputFocusState] =
    useState(false);
  const [searchKeywordInputFocusState, setSearchKeywordInputFocusState] =
    useState(false);

  const [searchBrandKeywordState, setSearchBrandKeywordState] = useState('');
  const [searchProductKeywordState, setSearchProductKeywordState] =
    useState('');
  const [searchCategoryKeywordState, setSearchCategoryKeywordState] =
    useState('');
  const [searchKeywordKeywordState, setSearchKeywordKeywordState] =
    useState('');

  const [searchCategoryIdState, setSearchCategoryIdState] = useState('');

  const [arrSearchProductsState, setArrSearchProductsState] = useState([]);
  const [arrSearchBrandtsState, setArrSearchBrandState] = useState([]);
  const [
    arrSearchCategoryMobileCollapseState,
    setArrSearchCategoryMobileCollapseState,
  ] = useState([]);

  const [blockSearchProductState, setBlockSearchProductState] = useState('');
  const [blockSearchCategoryState, setBlockSearchCategoryState] = useState('');
  const [blockSearchKeywordState, setBlockSearchKeywordState] = useState('');

  const [blockSearchProductMobileState, setBlockSearchProductMobileState] =
    useState('');
  const [blockSearchCategoryMobileState, setBlockSearchCategoryMobileState] =
    useState('');
  const [blockSearchKeywordMobileState, setBlockSearchKeywordMobileState] =
    useState('');

  const [fetchingHomeState, setFetchingHomeState] = useState(false);

  const [blockBannerState, setBlockBannerState] = useState('');
  const [blockBannerDotsState, setBlockBannerDotsState] = useState('');
  const [blockBannerMobileState, setBlockBannerMobileState] = useState('');
  const [scrollPositionState, setScrollPositionState] = useState(0);
  const [bannerFocusedItemState, setBannerFocusedItemState] = useState(0);
  const [bannerLeftMarginState, setBannerLeftMarginState] = useState(0);

  const [blockCommentState, setBlockCommentState] = useState('');
  const [blockCommentMobileState, setBlockCommentMobileState] = useState('');

  const [arrFeaturedState, setArrFeaturedState] = useState([]);
  const [arrFeaturedContentState, setArrFeaturedContentState] = useState([]);
  const [arrFeaturedContentMobileState, setArrFeaturedContentMobileState] =
    useState([]);
  const [blockFeaturedState, setBlockFeaturedState] = useState('');
  const [featuredFocusedItemState, setFeaturedFocusedItemState] = useState(-1);
  const [blockFeaturedContentState, setBlockFeaturedContentState] =
    useState('');
  const [blockFeaturedContentMobileState, setBlockFeaturedContentMobileState] =
    useState('');
  const [featuredFocusedContentPageState, setFeaturedFocusedContentPageState] =
    useState(0);

  const [arrPopularState, setArrPopularState] = useState([]);
  const [arrPopularContentState, setArrPopularContentState] = useState([]);
  const [blockPopularState, setBlockPopularState] = useState('');
  const [popularFocusedItemState, setPopularFocusedItemState] = useState(-1);
  const [blockPopularContentState, setBlockPopularContentState] = useState('');
  const [blockPopularContentMobileState, setBlockPopularContentMobileState] =
    useState('');
  const [popularFocusedContentPageState, setPopularFocusedContentPageState] =
    useState(0);

  const [arrBlogState, setArrBlogState] = useState([]);
  const [arrBlogCategoryState, setArrBlogCategoryState] = useState([]);
  const [blockBlogState, setBlockBlogState] = useState('');

  const [arrPromotionsState, setArrPromotionsState] = useState([]);
  const [blockPromotionsState, setBlockPromotionsState] = useState('');

  const [arrSearchCatState, setArrSearchCatState] = useState([]);
  const [blockSearchCatState, setBlockSearchCatState] = useState('');

  const [bottomSuggestionsExpanded, setBottomSuggestionsExpanded] =
    useState(false);
  const [bottomFaqExpanded, setBottomFaqExpanded] = useState(false);
  const [bottomSuggestionsMarkdown, setBottomSuggestionsMarkdown] =
    useState('');
  const [bottomFaqMarkdown, setBottomFaqMarkdown] = useState('');

  const [isCommentModalShow, setIsCommentModalShow] = useState(false);

  function appLaunch() {
    setFetchingHomeState(true);

    fetchAppLaunch().then(([success, error, data]) => {
      // console.log(data);

      if (data.notifications) {
        setArrNotificationState(data.notifications);
      }

      if (data.stores) {
        setArrStoresState(data.stores);
      }

      if (data.product_origins) {
        setArrProductOriginsState(data.product_origins);
      }

      if (data.factor_types) {
        setArrFactorTypesState(data.factor_types);
      }

      if (data.category_tree) {
        setArrCategoryState(data.category_tree);

        var arrSearchCatTmp = [];

        data.category_tree.forEach((element) => {
          element.second_categories.forEach((elementSecond) => {
            arrSearchCatTmp.push(elementSecond);
          });
        });

        setArrSearchCatState(arrSearchCatTmp);
      }

      if (data.search_skin_problem_keywords) {
        setArrSearchSkinProblemState(data.search_skin_problem_keywords);
      }

      if (data.review_tags) {
        setArrReviewTagsState(data.review_tags);
      }

      if (data.review_sources) {
        setArrReviewSourcesState(data.review_sources);
      }

      fetchHome();
      fetchBlog();
    });
  }

  async function fetchHome() {
    setFetchingHomeState(true);

    try {
      const [success, error, statusCode, data] = await httpCall(
        'get',
        process.env.NEXT_PUBLIC_API_HOST + '/api/v2/home',
        null,
        null
      );

      setFetchingHomeState(false);

      if (success == true) {
        if (data.promo_banners != null) {
          var arrBannerTmp = [];

          data.promo_banners.forEach((element) => {
            arrBannerTmp.push(element);
          });

          setArrBannerState(arrBannerTmp);
        }

        if (data.new_reviews != null) {
          var arrCommentTmp = [];

          data.new_reviews.forEach((element) => {
            arrCommentTmp.push(element);
          });

          setArrCommentState(arrCommentTmp);
        }

        if (data.sorra_featured != null) {
          var arrFeaturedTmp = [];

          data.sorra_featured.forEach((element) => {
            arrFeaturedTmp.push(element);
          });

          setArrFeaturedState(arrFeaturedTmp);
        }

        if (data.sorra_popular_categories != null) {
          var arrPopularTmp = [];

          data.sorra_popular_categories.forEach((element) => {
            arrPopularTmp.push(element);
          });

          setArrPopularState(arrPopularTmp);
        }

        if (data.promotions != null) {
          var arrPromotionsTmp = [];

          data.promotions.forEach((element) => {
            arrPromotionsTmp.push(element);
          });

          setArrPromotionsState(arrPromotionsTmp);
        }

        if (data.seo_recommend != null) {
          setBottomSuggestionsMarkdown(data.seo_recommend);
        } else {
          setBottomSuggestionsMarkdown('');
        }

        if (data.seo_question != null) {
          setBottomFaqMarkdown(data.seo_question);
        } else {
          setBottomFaqMarkdown('');
        }
      } else {
        console.log('api error', error);
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  async function fetchSearch() {
    try {
      const [success, error, statusCode, data] = await httpCall(
        'get',
        process.env.NEXT_PUBLIC_API_HOST +
          '/api/v2/search?keyword=' +
          searchProductKeywordState,
        null,
        null
      );

      if (success == true) {
        // console.log(data);

        if (data.brands != null) {
          var arrBrandTmp = [];

          data.brands.forEach((element) => {
            arrBrandTmp.push(element);
          });

          setArrSearchBrandState(arrBrandTmp);
        }

        if (data.products != null) {
          var arrProductTmp = [];

          data.products.forEach((element) => {
            arrProductTmp.push(element);
          });

          setArrSearchProductsState(arrProductTmp);
        }
      } else {
        console.log('api error', error);
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  async function fetchBlog() {
    try {
      const [success, error, statusCode, data] = await httpCall(
        'get',
        process.env.NEXT_PUBLIC_API_BLOG +
          'wp-json/wp/v2/posts?page=1&per_page=4',
        null,
        null
      );

      if (success == true) {
        // console.log(data);

        // setArrBlogState(data);

        try {
          const [
            successCategory,
            errorCategory,
            statusCodeCategory,
            dataCategory,
          ] = await httpCall(
            'get',
            process.env.NEXT_PUBLIC_API_BLOG + 'wp-json/wp/v2/categories',
            null,
            null
          );

          if (successCategory == true) {
            setArrBlogCategoryState(dataCategory);
            setArrBlogState(data);
          } else {
            console.log('api error', error);
          }
        } catch (error) {
          console.log('error', error);
        }
      } else {
        console.log('api error', error);
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  function loadBlockSearchProduct() {
    var blockSearchProductTmp = [];

    if (
      arrSearchProductsState.length == 0 &&
      arrSearchBrandtsState.length == 0
    ) {
      setBlockSearchProductState(
        <div className="flex h-full w-full items-center justify-center">
          <div className="space-y-3">
            <img className="m-[auto]" src="/svgs/search-empty.svg"></img>
            <div className="font-medium text-sorra-gray-800">沒有搜索結果</div>
          </div>
        </div>
      );

      setBlockSearchProductMobileState(
        <div className="flex h-[190px] w-full items-center justify-center">
          <div className="space-y-3">
            <img className="m-[auto]" src="/svgs/search-empty.svg"></img>
            <div className="font-medium text-sorra-gray-800">沒有搜索結果</div>
          </div>
        </div>
      );
    } else {
      arrSearchProductsState.forEach(function (element, index) {
        var imageURL = '';

        if (element.images.length > 0) {
          imageURL = element.images[0].url;
        } else {
          imageURL = 'images/placeholders/brand.png';
        }

        if (index < 2) {
          blockSearchProductTmp.push(
            <div
              className="flex h-[46px] cursor-pointer space-x-2"
              onClick={() => {
                setSearchBrandKeywordState(element.brand.name);
                setSearchProductKeywordState(element.name);
                hideSearchHint();
              }}
            >
              <img src={imageURL} className="h-[46px] w-[40px]"></img>
              <div className="h-full w-full">
                <div className="text-sm font-medium text-sorra-gray-900">
                  {element.name}
                </div>
                <div className="pt-0.5 text-sm font-medium text-sorra-gray-800">
                  {element.brand.name}
                </div>
              </div>
            </div>
          );
        }
      });

      blockSearchProductTmp.push(
        <div className="border-t-2 border-gray-100"></div>
      );

      arrSearchBrandtsState.forEach(function (element, index) {
        var imageURL = '';

        if (element.image != null) {
          imageURL = element.image.url;
        } else {
          imageURL = 'images/placeholders/brand.png';
        }

        if (index < 2) {
          blockSearchProductTmp.push(
            <div
              className="flex h-[46px] cursor-pointer space-x-2"
              onClick={() => {
                setSearchBrandKeywordState('');
                setSearchProductKeywordState(element.name);
                hideSearchHint();
              }}
            >
              <img
                src={imageURL}
                className="h-[46px] w-[40px] object-scale-down"
              ></img>
              <div className="h-full w-full">
                <div className="text-sm font-medium text-sorra-gray-900">
                  {element.name}
                </div>
                <div className="pt-0.5 text-sm font-medium text-sorra-gray-800">
                  {element.name}
                </div>
              </div>
            </div>
          );
        }
      });

      setBlockSearchProductState(blockSearchProductTmp);
      setBlockSearchProductMobileState(blockSearchProductTmp);
    }
  }

  function loadBlockSearchCategory() {
    var arrCategoryStateFiltered = [];

    if (searchCategoryKeywordState == '') {
      arrCategoryStateFiltered = arrCategoryState;
    } else {
      arrCategoryState.forEach((element) => {
        var categoryTmp = JSON.parse(JSON.stringify(element));
        var arrSubCategoryStateFiltered = [];

        element.second_categories.forEach((subelement) => {
          if (subelement.name.includes(searchCategoryKeywordState)) {
            arrSubCategoryStateFiltered.push(subelement);
          }
        });

        categoryTmp.second_categories = arrSubCategoryStateFiltered;

        arrCategoryStateFiltered.push(categoryTmp);
      });
    }

    var blockSearchCategoryTmp = [];
    var blockSearchCategoryMobileTmp = [];

    arrCategoryStateFiltered.forEach(function (element, index) {
      var blockSearchSubcategoryTmp = [];

      element.second_categories.forEach((subelement) => {
        blockSearchSubcategoryTmp.push(
          <div
            className="flex cursor-pointer gap-x-0.5 hover:ml-1"
            onClick={() => {
              setSearchCategoryKeywordState(subelement.name);
              setSearchCategoryIdState(subelement.id);
              hideSearchHint();
            }}
          >
            <img
              src="/svgs/index-partnership-arrow.svg"
              className="mb-[auto] mt-[2px]"
            ></img>
            <div className="text-sm font-medium text-sorra-gray-900">
              {subelement.name}
            </div>
          </div>
        );
      });

      blockSearchCategoryTmp.push(
        <div className="space-y-2">
          <div className="text-sorra-gray-900">{element.name}</div>
          {blockSearchSubcategoryTmp}
        </div>
      );

      blockSearchCategoryMobileTmp.push(
        <div className="border-b-2 border-gray-100">
          <div
            className="flex cursor-pointer gap-x-4 p-4 text-sm font-medium text-sorra-gray-800"
            onClick={() => pressSearchCategoryMobileExpands(index)}
          >
            <div className="grow">{element.name}</div>
            <img
              src={
                arrSearchCategoryMobileCollapseState.includes(index)
                  ? '/svgs/chevron-down.svg'
                  : '/svgs/chevron-up.svg'
              }
            ></img>
          </div>
          <div
            className={
              'grid grid-cols-2 gap-y-4 px-4 pb-4' +
              (blockSearchSubcategoryTmp.length == 0 ||
              arrSearchCategoryMobileCollapseState.includes(index)
                ? ' hidden'
                : '')
            }
          >
            {blockSearchSubcategoryTmp}
          </div>
        </div>
      );
    });

    setBlockSearchCategoryState(
      <div className="flex grid max-h-full w-full grid-cols-5 gap-x-4 gap-y-8 overflow-y-scroll px-2 pb-6">
        {blockSearchCategoryTmp}
      </div>
    );

    setBlockSearchCategoryMobileState(
      <div className="w-[calc(100% + 32px)] mx-[-16px] mt-[-32px] max-h-full overflow-y-scroll">
        {blockSearchCategoryMobileTmp}
      </div>
    );
  }

  function loadBlockSearchKeyword() {
    var blockSearchKeywordTmp = [];

    arrSearchSkinProblemState.forEach((element) => {
      blockSearchKeywordTmp.push(
        <div
          className="flex cursor-pointer gap-x-0.5 hover:ml-1"
          onClick={() => {
            setSearchKeywordKeywordState(element.name);
            hideSearchHint();
          }}
        >
          <img
            src="/svgs/index-partnership-arrow.svg"
            className="mb-[auto] mt-[2px]"
          ></img>
          <div className="text-sm font-medium text-sorra-gray-900">
            {element.name}
          </div>
        </div>
      );
    });

    setBlockSearchKeywordState(
      <>
        <div className="flex grid max-h-full w-full grid-cols-2 gap-x-4 gap-y-2 overflow-y-scroll px-2">
          <div className="col-span-2 text-sorra-gray-900">熱門搜尋</div>
          {blockSearchKeywordTmp}
        </div>
      </>
    );

    setBlockSearchKeywordMobileState(
      <>
        <div className="flex grid max-h-full w-full grid-cols-2 gap-x-4 gap-y-2 px-2">
          <div className="col-span-2 text-sorra-gray-900">熱門搜尋</div>
          {blockSearchKeywordTmp}
        </div>
      </>
    );
  }

  function loadBlockBanner() {
    var blockBannerTmp = [];

    if (fetchingHomeState == true) {
      blockBannerTmp = (
        <IndexBannerCarouselMobile
          className="home-banner h-full w-full overflow-visible"
          props={{ showArrows: true }}
        >
          {<Skeleton width={'100%'} height={'100%'} />}
        </IndexBannerCarouselMobile>
      );
    } else {
      blockBannerTmp = (
        <IndexBannerCarouselMobile
          className="home-banner h-full w-full overflow-visible"
          props={{ showArrows: true }}
        >
          {_.filter(arrBannerState, (b) => {
            return _.get(b, 'image');
          }).map((element) => {
            var backgroundImageURL = '';
            var hrefURL = '';
            var hrefNewTab = false;

            if (element.image != null) {
              backgroundImageURL = element.image.url;
            }

            if (element.url != null) {
              hrefURL = element.url;
            }

            hrefNewTab = element.new_tab;

            return (
              <a
                key={element.id}
                href={!hrefURL ? '#' : hrefURL}
                onClick={() => {
                  MixpanelConfig.track('Click', {
                    Component: 'Home Page Ad',
                    'Ad Name': element.name || null,
                    'Ad URL': element.url || null,
                  });
                }}
                target={hrefNewTab == true ? '_blank' : ''}
              >
                <div
                  className={
                    'index-banner-item-mobile relative h-full w-full px-6 py-8'
                  }
                  style={{ backgroundImage: 'url(' + backgroundImageURL + ')' }}
                ></div>
              </a>
            );
          })}
        </IndexBannerCarouselMobile>
      );
    }

    if (arrBannerState.length > 0 || fetchingHomeState == true) {
      setBlockBannerState(blockBannerTmp);
    }
  }

  function loadBlockBannerMobile() {
    var blockBannerMobileTmp = [];

    if (fetchingHomeState == true) {
      blockBannerMobileTmp = (
        <IndexBannerCarouselMobile
          className="home-banner h-full w-full overflow-visible"
          props={{ showArrows: false }}
        >
          <div className={'index-banner-item-mobile relative h-full w-full'}>
            {<Skeleton width={'100%'} height={'100%'} />}
          </div>
        </IndexBannerCarouselMobile>
      );
    } else {
      blockBannerMobileTmp = (
        <IndexBannerCarouselMobile
          className="home-banner h-full w-full overflow-visible"
          props={{ showArrows: false }}
        >
          {_.filter(arrBannerState, (b) => {
            return _.get(b, 'mobile_image');
          }).map((element) => {
            var backgroundImageURL = '';
            var hrefURL = '';
            var hrefNewTab = false;

            if (element.mobile_image != null) {
              backgroundImageURL = element.mobile_image.url;
            } else if (element.image != null) {
              backgroundImageURL = element.image.url;
            }

            if (element.url != null) {
              hrefURL = element.url;
            }

            hrefNewTab = element.new_tab;

            return (
              <a
                key={element.id}
                href={!hrefURL ? '#' : hrefURL}
                onClick={() => {
                  MixpanelConfig.track('Click', {
                    Component: 'Home Page Ad',
                    'Ad Name': element.name || null,
                    'Ad URL': element.url || null,
                  });
                }}
                target={hrefNewTab == true ? '_blank' : ''}
              >
                <div
                  className={
                    'index-banner-item-mobile relative h-full w-full px-6 py-8'
                  }
                  style={{ backgroundImage: 'url(' + backgroundImageURL + ')' }}
                ></div>
              </a>
            );
          })}
        </IndexBannerCarouselMobile>
      );
    }

    if (arrBannerState.length > 0 || fetchingHomeState == true) {
      setBlockBannerMobileState(blockBannerMobileTmp);
    }
  }

  function loadBlockFeatured() {
    var blockFeaturedTmp = [];

    arrFeaturedState.forEach((element) => {
      blockFeaturedTmp.push(
        <>
          <div
            className={
              'index-category-item relative' +
              (element.id == featuredFocusedItemState ? ' selected' : '')
            }
            onClick={() => setFeaturedFocusedItemState(element.id)}
          >
            {element.name}
          </div>
        </>
      );
    });

    setBlockFeaturedState(blockFeaturedTmp);
  }

  function loadBlockFeaturedContent() {
    console.log('loadBlockFeaturedContent');

    var blockFeaturedContentTmp = [];
    var blockFeaturedContentMobileTmp = [];

    if (fetchingHomeState == true) {
      for (let index = 0; index < 4; index++) {
        blockFeaturedContentTmp.push(
          <>
            <div className="index-featured-item relative cursor-pointer">
              <div className="h-full w-full overflow-hidden rounded-[10px] bg-[#FFFFFF]">
                <Skeleton width={'100%'} height={'100%'} />
              </div>
            </div>
          </>
        );
      }
    } else {
      arrFeaturedContentState.forEach(function (content, contentIndex) {
        if (content.images != null) {
          //product
          var imageURL = '';
          var imageAlt = '';
          var price = -1;
          var storeURL = '';
          var storeName = '';
          var origin = '';
          var factFallbackName = '';
          var factFallbackBgColor = '';
          var factFallbackFontColor = '';
          var factsCount = 0;
          var rating = 0;
          var total = 0;
          var ratingRate = '';
          var ratingPercentage = content.rating
            ? (content.rating.positive / content.rating.total) * 100
            : 0;

          if (content.images.length > 0) {
            imageURL = content.images[0].url;
            imageAlt = content.images[0].alternativeText;
          }

          if (content.product_stores.length > 0) {
            price = content.product_stores[0].price;

            arrStoresState.forEach((storeElement) => {
              if (storeElement.id == content.product_stores[0].store) {
                if (storeElement.image != null) {
                  storeURL = storeElement.image.url;
                }
                storeName = storeElement.name;
              }
            });
          }

          arrProductOriginsState.forEach((originElement) => {
            if (originElement.id == content.origin) {
              origin = originElement.name + '製';
            }
          });

          factsCount = content.facts.length;

          if (origin == '') {
            if (factsCount > 0) {
              factFallbackName = content.facts[0].name;
              factFallbackBgColor = content.facts[0].bg_color;
              factFallbackFontColor = content.facts[0].text_color;
              factsCount -= 1;
            }
          }

          if (content.rating) {
            rating = content.rating.rate;

            if (content.rating.total) {
              total = content.rating.total;
            }
          }

          if (ratingPercentage < 49) {
            ratingRate += '🤔';
          } else if (ratingPercentage < 79) {
            ratingRate += '👌';
          } else {
            ratingRate += '❤️';
          }

          if (
            contentIndex >= featuredFocusedContentPageState * 4 &&
            contentIndex <= featuredFocusedContentPageState * 4 + 3
          ) {
            blockFeaturedContentTmp.push(
              <>
                <div
                  className="index-featured-item relative cursor-pointer"
                  onClick={() => {
                    window.location.href = '/product/' + content.id;
                  }}
                >
                  <div className="aspect-[1/1] w-full rounded-lg bg-gray-50 p-9">
                    <img src={imageURL} alt={imageAlt}></img>
                  </div>
                  <div className="truncate pt-6 text-sm text-sorra-gray-800">
                    {content.brand.name}
                  </div>
                  <div className="truncate pt-1 text-lg font-medium text-sorra-gray-900">
                    {content.name}
                    <br />
                    {content.en_name}
                  </div>
                  <div className="truncate pt-1 text-lg font-medium text-sorra-pink">
                    {ratingRate} {rating}%
                    <span className="font-regular text-base text-sorra-gray-500">
                      {' (' + total + ')'}
                    </span>
                  </div>
                  <div className="flex items-center gap-x-2 pt-2">
                    <div
                      className={
                        'index-featured-tag px-2 py-1 text-xs font-medium' +
                        (origin == '' ? ' hidden' : '')
                      }
                    >
                      {origin}
                    </div>
                    <div
                      className={
                        'index-featured-tag px-2 py-1 text-xs font-medium' +
                        (factFallbackName == '' ? ' hidden' : '')
                      }
                      style={{
                        backgroundColor: factFallbackBgColor,
                        color: factFallbackFontColor,
                      }}
                    >
                      {factFallbackName}
                    </div>
                    <div
                      className={
                        'index-featured-tagMore px-2 py-2 text-xs font-medium' +
                        (factsCount <= 0 ? ' hidden' : '')
                      }
                    >
                      +{factsCount}
                    </div>
                  </div>
                  <div className={'flex pt-3' + (price == -1 ? ' hidden' : '')}>
                    <div className="text-lg font-medium text-sorra-gray-900">
                      HK$ {price} @ {storeURL == '' ? storeName : ''}
                    </div>
                    {storeURL != '' ? (
                      <img className="h-[28px] pl-1" src={storeURL}></img>
                    ) : (
                      ''
                    )}
                  </div>
                  <div
                    className={
                      'pt-1 text-sm text-sorra-gray-800' +
                      (content.product_stores.length - 1 <= 0 ? ' hidden' : '')
                    }
                  >
                    還有{content.product_stores.length - 1}間商戶亦有售
                  </div>
                </div>
              </>
            );
          }
        } else {
          //sorra_featured_banner
          var imageURL = '';
          var imageAlt = '';

          if (content.image != null) {
            if (content.image.url != null) {
              imageURL = content.image.url;
            }

            if (content.image.alternativeText != null) {
              imageAlt = content.image.alternativeText;
            }
          }

          if (
            contentIndex >= featuredFocusedContentPageState * 4 &&
            contentIndex <= featuredFocusedContentPageState * 4 + 3
          ) {
            blockFeaturedContentTmp.push(
              <>
                <div
                  className="index-featured-item relative cursor-pointer"
                  onClick={() => {
                    MixpanelConfig.track('Click', {
                      Component: 'Home Page Product Ad',
                      'Ad Name': content.name || null,
                      'Ad URL': content.url || null,
                    });
                    window.open(
                      content.url,
                      content.new_tab == true ? '_blank' : ''
                    );
                  }}
                >
                  <div className="h-full w-full overflow-hidden rounded-[10px] bg-[#FFFFFF]">
                    <img
                      className="h-full w-full object-cover"
                      src={imageURL}
                      alt={imageAlt}
                    ></img>
                  </div>
                </div>
              </>
            );
          }
        }
      });
    }

    if (fetchingHomeState == true) {
      for (let index = 0; index < 4; index++) {
        blockFeaturedContentMobileTmp.push(
          <>
            <div
              className="index-featured-item relative h-[342px] cursor-pointer"
              onClick={() => {
                window.open(
                  content.url,
                  content.new_tab == true ? '_blank' : ''
                );
              }}
            >
              <div className="h-full w-full overflow-hidden rounded-[10px] bg-[#FFFFFF]">
                <Skeleton width={'100%'} height={'100%'} />
              </div>
            </div>
          </>
        );
      }
    } else {
      arrFeaturedContentMobileState.forEach(function (content, contentIndex) {
        if (content.images != null) {
          //product
          var imageURL = '';
          var imageAlt = '';
          var price = -1;
          var storeURL = '';
          var storeName = '';
          var origin = '';
          var factFallbackName = '';
          var factFallbackBgColor = '';
          var factFallbackFontColor = '';
          var factsCount = 0;
          var rating = 0;
          var total = 0;
          var ratingRate = '';
          var ratingPercentage = content.rating
            ? (content.rating.positive / content.rating.total) * 100
            : 0;

          if (content.images.length > 0) {
            imageURL = content.images[0].url;
            imageAlt = content.images[0].alternativeText;
          }

          if (content.product_stores.length > 0) {
            price = content.product_stores[0].price;

            arrStoresState.forEach((storeElement) => {
              if (storeElement.id == content.product_stores[0].store) {
                if (storeElement.image != null) {
                  storeURL = storeElement.image.url;
                }
                storeName = storeElement.name;
              }
            });
          }

          arrProductOriginsState.forEach((originElement) => {
            if (originElement.id == content.origin) {
              origin = originElement.name + '製';
            }
          });

          factsCount = content.facts.length;

          if (origin == '') {
            if (factsCount > 0) {
              factFallbackName = content.facts[0].name;
              factFallbackBgColor = content.facts[0].bg_color;
              factFallbackFontColor = content.facts[0].text_color;
              factsCount -= 1;
            }
          }

          if (content.rating) {
            rating = content.rating.rate;

            if (content.rating.total) {
              total = content.rating.total;
            }
          }

          if (ratingPercentage < 49) {
            ratingRate += '🤔';
          } else if (ratingPercentage < 79) {
            ratingRate += '👌';
          } else {
            ratingRate += '❤️';
          }

          blockFeaturedContentMobileTmp.push(
            <>
              <div
                className="index-featured-item relative cursor-pointer"
                onClick={() => {
                  window.location.href = '/product/' + content.id;
                }}
              >
                <div className="aspect-[1/1] w-full rounded-lg bg-gray-50 p-2">
                  <img
                    src={
                      imageURL == ''
                        ? '/images/placeholders/product.png'
                        : imageURL
                    }
                    alt={imageAlt}
                  ></img>
                </div>
                <div className="truncate pt-6 text-sm text-sorra-gray-800">
                  {content.brand.name}
                </div>
                <div className="pt-1 text-sm font-medium text-sorra-gray-900">
                  <div className="truncate">{content.name}</div>
                  <div className="truncate">{content.en_name}</div>
                </div>
                <div className="flex items-center space-x-1 pt-1">
                  <div className="flex items-center text-sm font-bold text-sorra-pink">
                    {ratingRate} {rating}%
                  </div>
                  <div className="text-xs font-medium text-gray-500">{`(${total})`}</div>
                </div>
                <div className="flex items-center gap-x-2 pt-[6px]">
                  <div
                    className={
                      'index-featured-tag px-2 py-[1px] text-xs font-medium' +
                      (origin == '' ? ' hidden' : '')
                    }
                  >
                    {origin}
                  </div>
                  <div
                    className={
                      'index-featured-tag px-2 py-[1px] text-xs font-medium' +
                      (factFallbackName == '' ? ' hidden' : '')
                    }
                    style={{
                      backgroundColor: factFallbackBgColor,
                      color: factFallbackFontColor,
                    }}
                  >
                    {factFallbackName}
                  </div>
                  <div
                    className={
                      'index-featured-tagMore px-2 py-[1px] text-xs font-medium' +
                      (factsCount <= 0 ? ' hidden' : '')
                    }
                  >
                    +{factsCount}
                  </div>
                  <div className="bottom-1 right-0 ml-[auto] text-sm font-bold"></div>
                </div>
                <div
                  className={'flex pt-[6px]' + (price == -1 ? ' hidden' : '')}
                >
                  <div className="text-sm font-medium text-sorra-gray-900">
                    HK$ {price} @{' '}
                    {storeURL != '' ? (
                      <img className="h-[16px] pl-1" src={storeURL}></img>
                    ) : (
                      <div className="text-sm font-medium text-sorra-gray-900">
                        {storeName}
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className={
                    'pt-1 text-xs text-sorra-gray-800' +
                    (content.product_stores.length - 1 <= 0 ? ' hidden' : '')
                  }
                >
                  還有{content.product_stores.length - 1}間商戶亦有售
                </div>
              </div>
            </>
          );
        } else {
          //sorra_featured_banner
          var imageURL = '';
          var imageAlt = '';

          if (content.mobile_image != null) {
            if (content.mobile_image.url != null) {
              imageURL = content.mobile_image.url;
            }

            if (content.mobile_image.alternativeText != null) {
              imageAlt = content.mobile_image.alternativeText;
            }
          }

          if (
            contentIndex >= featuredFocusedContentPageState * 4 &&
            contentIndex <= featuredFocusedContentPageState * 4 + 3
          ) {
            blockFeaturedContentMobileTmp.push(
              <>
                <div
                  className="index-featured-item relative h-[342px] cursor-pointer"
                  onClick={() => {
                    MixpanelConfig.track('Click', {
                      Component: 'Home Page Product Ad',
                      'Ad Name': content.name || null,
                      'Ad URL': content.url || null,
                    });
                    window.open(
                      content.url,
                      content.new_tab == true ? '_blank' : ''
                    );
                  }}
                >
                  <div className="h-full w-full overflow-hidden rounded-[10px] bg-[#FFFFFF]">
                    <img
                      className="h-full w-full object-cover"
                      src={imageURL}
                      alt={imageAlt}
                    ></img>
                  </div>
                </div>
              </>
            );
          }
        }
      });
    }

    setBlockFeaturedContentState(blockFeaturedContentTmp);
    setBlockFeaturedContentMobileState(blockFeaturedContentMobileTmp);
  }

  function loadBlockComment() {
    var blockCommentTmp = [];
    var arrCommentPage = ['', '', '', '', '', ''];

    if (fetchingHomeState == true) {
      var blockCommentGroupTmp = [];

      for (let index = 0; index < 3; index++) {
        blockCommentGroupTmp.push(
          <div className="index-comment-item relative h-full cursor-pointer">
            <Skeleton width={'100%'} height={'100%'} />
          </div>
        );
      }

      blockCommentTmp = (
        <IndexCommentCarouselMobile
          className="home-comments h-full w-full overflow-visible"
          props={{ showArrows: true, showIndicators: true }}
        >
          <div className="grid h-full w-full grid-cols-3 gap-x-[26px]">
            {blockCommentGroupTmp}
          </div>
        </IndexCommentCarouselMobile>
      );
    } else {
      blockCommentTmp = (
        <IndexCommentCarouselMobile
          className="home-comments h-full w-full overflow-visible"
          props={{ showArrows: true, showIndicators: true }}
        >
          {arrCommentPage.map((element, index) => {
            var blockCommentGroupTmp = [];

            for (let i = 0; i < 3; i++) {
              var factor = '';
              var productRedirectID = '';
              var productImageURL = '';
              var productBrandName = '';
              var productName = '';
              var productNameEN = '';
              var sorraUserPicture = '';
              var maskName = '';
              var ageString = '';
              var commentCountString = '';
              var rating = '';
              var certImages = [];
              var reviewTags = [];
              var commentString = '';
              var comparisonImages = [];
              var timeString = '';
              var source = '';
              var isRealCert = false;
              var isActivity = false;

              if (index * 3 + i < arrCommentState.length) {
                var reviewObject = arrCommentState[index * 3 + i];

                // console.log(reviewObject)

                if (reviewObject.product.images != null) {
                  if (reviewObject.product.images.length > 0) {
                    productImageURL = reviewObject.product.images[0].url;
                  }
                }

                if (reviewObject.product.brand != null) {
                  productBrandName = reviewObject.product.brand.name;
                }

                productRedirectID = reviewObject.product.id;
                productName = reviewObject.product.name;
                productNameEN = reviewObject.product.en_name;

                // console.log('aaa')
                // console.log(productRedirectID)
                // console.log('bbb')

                if (reviewObject.sorra_user != null) {
                  if (reviewObject.sorra_user.picture != null) {
                    sorraUserPicture = reviewObject.sorra_user.picture;
                  }

                  if (reviewObject.sorra_user?.age_range) {
                    ageString += `| ${reviewObject.sorra_user.age_range} 歲`;
                  }

                  if (reviewObject.sorra_user?.review_count) {
                    commentCountString += `| ${reviewObject.sorra_user.review_count}則評價`;
                  }
                }

                maskName = reviewObject.mask_name;

                arrFactorTypesState.forEach((element) => {
                  if (element.alias == 'skin') {
                    element.factors.forEach((elementFactor) => {
                      if (elementFactor.id == reviewObject.factors[0]) {
                        factor = elementFactor.name;
                      }
                    });
                  }

                  if (element.alias == 'hair') {
                    element.factors.forEach((elementFactor) => {
                      if (elementFactor.id == reviewObject.factors[0]) {
                        factor = elementFactor.name;
                      }
                    });
                  }
                });

                switch (reviewObject.score) {
                  case 0:
                    rating = '👌 中性';
                    break;

                  case 1:
                    rating = '❤️ 好評';
                    break;

                  case -1:
                    rating = '👿 差評';
                    break;

                  default:
                    break;
                }

                certImages = reviewObject.cert_images;
                reviewTags = reviewObject.review_tags;
                commentString = reviewObject.comment;
                comparisonImages = reviewObject.comparison_images;
                isRealCert = reviewObject.is_real_cert;
                isActivity = reviewObject.activity ? true : false;

                var time = new Date(reviewObject.created_at);
                timeString =
                  time.getDate() +
                  '/' +
                  (time.getMonth() + 1) +
                  '/' +
                  time.getFullYear() +
                  ' ' +
                  (time.getHours() < 10
                    ? '0' + time.getHours()
                    : time.getHours()) +
                  ':' +
                  (time.getMinutes() < 10
                    ? '0' + time.getMinutes()
                    : time.getMinutes());

                arrReviewSourcesState.forEach((element) => {
                  if (element.id == reviewObject.source) {
                    switch (element.name) {
                      case 'user':
                        source = 'Sorra官網';
                        break;

                      case 'ig':
                        source = 'Sorra IG社群';
                        break;

                      default:
                        source = element.name;
                        break;
                    }
                  }
                });
              }

              blockCommentGroupTmp.push(
                <a
                  href={'/product/' + productRedirectID}
                  className="index-comment-item relative h-full cursor-pointer px-[20px] py-[16px]"
                >
                  <div className="flex h-[88px] gap-x-[12px] rounded-[8px] bg-white px-[16px] py-[10px]">
                    <img
                      src={
                        productImageURL == ''
                          ? '/images/placeholders/product.png'
                          : productImageURL
                      }
                      className="index-comment-item-product-image rounded-[4px]"
                    ></img>
                    <div className="flex grow items-center">
                      <div className="grid w-full">
                        <div className="text-left text-xs text-sorra-gray-800">
                          {productBrandName}
                        </div>
                        <div className="truncate text-left text-sm font-medium text-sorra-gray-900">
                          {productName}
                        </div>
                        <div className="truncate text-left text-sm font-medium text-sorra-gray-900">
                          {productNameEN}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-[22px] flex items-center gap-x-2 px-[4px]">
                    <div className="h-[40px] w-[40px]">
                      {sorraUserPicture ? (
                        <img
                          className="h-full w-full rounded-full"
                          src={sorraUserPicture}
                        />
                      ) : (
                        <div className="flex h-full w-full items-center justify-center rounded-full bg-[#D6DDFB80] text-sorra-blue">
                          {`${maskName.charAt(0)}${maskName.charAt(
                            maskName.length - 1
                          )}`}
                        </div>
                      )}
                    </div>
                    <div className="grow">
                      <div className="text-left text-sm font-bold text-[#111928]">
                        {maskName}
                      </div>
                      <div className="mt-1 text-left text-xs text-sorra-gray-800">
                        {!factor ? '無註明' : factor} {ageString}{' '}
                        {commentCountString}
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center gap-x-2 pt-4">
                    <span className="text-base font-bold text-[#111928]">
                      {rating}
                    </span>

                    {isRealCert ? (
                      <div className="cursor-default rounded-md bg-[#D6DDFB80] px-2 py-1 text-[12px] text-sorra-blue">
                        真實用家認證
                      </div>
                    ) : null}

                    {isActivity ? (
                      <div className="cursor-default rounded-md bg-[#FFDCC680] px-2 py-1 text-[12px] text-[#FFA66D]">
                        試用品
                      </div>
                    ) : null}

                    {reviewTags.map((item) => {
                      const targetTag = arrReviewTagsState.find(
                        (tagItem) => item == tagItem.id
                      );
                      return (
                        <div
                          style={{
                            color: targetTag.text_color,
                            background: targetTag.background_color,
                          }}
                          className={`cursor-default rounded-md px-2 py-1 text-[12px]`}
                        >
                          {targetTag.name}
                        </div>
                      );
                    })}
                  </div>

                  <div className="mb-[16px] line-clamp-2 h-[52px] w-full px-[4px] pt-1 text-left text-base text-[#111928]">
                    {commentString}
                  </div>

                  {comparisonImages.length > 0 || certImages.length > 0 ? (
                    <div className="flex items-center space-x-2 px-[4px] pt-[16px]">
                      {certImages.map((item, index) => (
                        <img
                          src={item.url}
                          className="index-comment-item-review-image h-[70px] w-[70px] cursor-pointer rounded object-cover"
                        />
                      ))}
                      {comparisonImages.map((item, index) => (
                        <img
                          src={item.url}
                          className="index-comment-item-review-image h-[70px] w-[70px] cursor-pointer rounded object-cover"
                        />
                      ))}
                    </div>
                  ) : null}

                  <div className="mb-0 place-self-end px-[4px] pt-[24px] text-left text-xs text-[#4A4A4A]">
                    {timeString} 在 {source} 評價
                  </div>
                </a>
              );
            }

            return (
              <div className="grid h-full w-full grid-cols-3 gap-x-[26px]">
                {blockCommentGroupTmp}
              </div>
            );
          })}
        </IndexCommentCarouselMobile>
      );
    }

    if (arrCommentState.length > 0 || fetchingHomeState == true) {
      setBlockCommentState(blockCommentTmp);
    }
  }

  function loadBlockCommentMobile() {
    var blockCommentTmp = [];

    if (fetchingHomeState == true) {
      for (let index = 0; index < 3; index++) {
        blockCommentTmp.push(
          <div className="index-comment-item relative h-full w-[300px] cursor-pointer">
            <Skeleton width={'100%'} height={'100%'} />
          </div>
        );
      }
    } else {
      arrCommentState.forEach(function (element, elementIndex) {
        var factor = '';
        var productRedirectID = '';
        var productImageURL = '';
        var productBrandName = '';
        var productName = '';
        var productNameEN = '';
        var sorraUserPicture = '';
        var maskName = '';
        var ageString = '';
        var commentCountString = '';
        var rating = '';
        var certImages = [];
        var reviewTags = [];
        var commentString = '';
        var comparisonImages = [];
        var timeString = '';
        var source = '';
        var isRealCert = false;
        var isActivity = false;

        var reviewObject = element;

        // console.log(reviewObject)

        if (reviewObject.product.images != null) {
          if (reviewObject.product.images.length > 0) {
            productImageURL = reviewObject.product.images[0].url;
          }
        }

        if (reviewObject.product.brand != null) {
          productBrandName = reviewObject.product.brand.name;
        }

        productRedirectID = reviewObject.product.id;
        productName = reviewObject.product.name;
        productNameEN = reviewObject.product.en_name;

        if (reviewObject.sorra_user != null) {
          if (reviewObject.sorra_user.picture != null) {
            sorraUserPicture = reviewObject.sorra_user.picture;
          }

          if (reviewObject.sorra_user?.age_range) {
            ageString += `| ${reviewObject.sorra_user.age_range} 歲`;
          }

          if (reviewObject.sorra_user?.review_count) {
            commentCountString += `| ${reviewObject.sorra_user.review_count}則評價`;
          }
        }

        maskName = reviewObject.mask_name;

        arrFactorTypesState.forEach((element) => {
          if (element.alias == 'skin') {
            element.factors.forEach((elementFactor) => {
              if (elementFactor.id == reviewObject.factors[0]) {
                factor = elementFactor.name;
              }
            });
          }

          if (element.alias == 'hair') {
            element.factors.forEach((elementFactor) => {
              if (elementFactor.id == reviewObject.factors[0]) {
                factor = elementFactor.name;
              }
            });
          }
        });

        switch (reviewObject.score) {
          case 0:
            rating = '👌 中性';
            break;

          case 1:
            rating = '❤️ 好評';
            break;

          case -1:
            rating = '👿 差評';
            break;

          default:
            break;
        }

        certImages = reviewObject.cert_images;
        reviewTags = reviewObject.review_tags;
        commentString = reviewObject.comment;
        comparisonImages = reviewObject.comparison_images;
        isRealCert = reviewObject.is_real_cert;
        isActivity = reviewObject.activity ? true : false;

        var time = new Date(reviewObject.created_at);
        timeString =
          time.getDate() +
          '/' +
          (time.getMonth() + 1) +
          '/' +
          time.getFullYear() +
          ' ' +
          (time.getHours() < 10 ? '0' + time.getHours() : time.getHours()) +
          ':' +
          (time.getMinutes() < 10
            ? '0' + time.getMinutes()
            : time.getMinutes());

        arrReviewSourcesState.forEach((element) => {
          if (element.id == reviewObject.source) {
            switch (element.name) {
              case 'user':
                source = 'Sorra官網';
                break;

              case 'ig':
                source = 'Sorra IG社群';
                break;

              default:
                source = element.name;
                break;
            }
          }
        });

        blockCommentTmp.push(
          <div
            className="index-comment-item relative h-full w-[300px] cursor-pointer px-[20px] py-[16px]"
            onClick={() => {
              window.location.href = '/product/' + productRedirectID;
            }}
          >
            <div className="flex h-[88px] gap-x-[12px] rounded-[8px] bg-white px-[16px] py-[10px]">
              <img
                src={
                  productImageURL == ''
                    ? '/images/placeholders/product.png'
                    : productImageURL
                }
                className="index-comment-item-product-image rounded-[4px]"
              ></img>
              <div className="flex grow items-center">
                <div className="grid w-full">
                  <div className="text-left text-xs text-sorra-gray-800">
                    {productBrandName}
                  </div>
                  <div className="truncate text-left text-sm font-medium text-sorra-gray-900">
                    {productName}
                  </div>
                  <div className="truncate text-left text-sm font-medium text-sorra-gray-900">
                    {productNameEN}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-[22px] flex items-center gap-x-2 px-[4px]">
              <div className="h-[40px] w-[40px]">
                {sorraUserPicture ? (
                  <img
                    className="h-full w-full rounded-full"
                    src={sorraUserPicture}
                  />
                ) : (
                  <div className="flex h-full w-full items-center justify-center rounded-full bg-[#D6DDFB80] text-sorra-blue">
                    {`${maskName.charAt(0)}${maskName.charAt(
                      maskName.length - 1
                    )}`}
                  </div>
                )}
              </div>
              <div className="grow">
                <div className="text-left text-sm font-bold text-[#111928]">
                  {maskName}
                </div>
                <div className="mt-1 text-left text-xs text-sorra-gray-800">
                  {!factor ? '無註明' : factor} {ageString} {commentCountString}
                </div>
              </div>
            </div>
            <div className="flex items-center gap-x-2 pt-4">
              <span className="text-base font-bold text-[#111928]">
                {rating}
              </span>

              {isRealCert ? (
                <div className="cursor-default rounded-md bg-[#D6DDFB80] px-2 py-1 text-[12px] text-sorra-blue">
                  真實用家認證
                </div>
              ) : null}

              {isActivity ? (
                <div className="cursor-default rounded-md bg-[#FFDCC680] px-2 py-1 text-[12px] text-[#FFA66D]">
                  試用品
                </div>
              ) : null}

              {reviewTags.map((item) => {
                const targetTag = arrReviewTagsState.find(
                  (tagItem) => item == tagItem.id
                );
                return (
                  <div
                    style={{
                      color: targetTag.text_color,
                      background: targetTag.background_color,
                    }}
                    className={`cursor-default rounded-md px-2 py-1 text-[12px]`}
                  >
                    {targetTag.name}
                  </div>
                );
              })}
            </div>

            <div className="mb-[16px] line-clamp-2 h-[52px] w-full px-[4px] pt-1 text-left text-base text-[#111928]">
              {commentString}
            </div>

            {comparisonImages.length > 0 || certImages.length > 0 ? (
              <div className="flex items-center space-x-2 px-[4px] pt-[16px]">
                {certImages.map((item, index) => (
                  <img
                    src={item.url}
                    className="index-comment-item-review-image h-[70px] w-[70px] cursor-pointer rounded object-cover"
                  />
                ))}
                {comparisonImages.map((item, index) => (
                  <img
                    src={item.url}
                    className="index-comment-item-review-image h-[70px] w-[70px] cursor-pointer rounded object-cover"
                  />
                ))}
              </div>
            ) : null}

            <div className="mb-0 place-self-end px-[4px] pt-[24px] text-left text-xs text-[#4A4A4A]">
              {timeString} 在 {source} 評價
            </div>
          </div>
        );
      });
    }

    if (arrCommentState.length > 0 || fetchingHomeState == true) {
      setBlockCommentMobileState(blockCommentTmp);
    }
  }

  function loadBlockPopular() {
    var blockPopularTmp = [];

    arrPopularState.forEach((element) => {
      blockPopularTmp.push(
        <>
          <div
            className={
              'index-category-item relative' +
              (element.id == popularFocusedItemState ? ' selected' : '')
            }
            onClick={() => setPopularFocusedItemState(element.id)}
          >
            {element.name}
          </div>
        </>
      );
    });

    setBlockPopularState(blockPopularTmp);
  }

  function loadBlockPopularContent() {
    var blockPopularContentTmp = [];
    var blockPopularContentMobileTmp = [];

    arrPopularContentState.forEach(function (content, contentIndex) {
      // console.log('content');
      // console.log({ content });

      var imageURL = '';
      var imageAlt = '';
      var price = -1;
      var storeURL = '';
      var storeName = '';
      var origin = '';
      var factFallbackName = '';
      var factFallbackBgColor = '';
      var factFallbackFontColor = '';
      var factsCount = 0;
      var rating = 0;
      var total = 0;
      var ratingRate = '';
      var ratingPercentage = content.rating
        ? (content.rating.positive / content.rating.total) * 100
        : 0;

      if (content.images.length > 0) {
        imageURL = content.images[0].url;
        imageAlt = content.images[0].alternativeText;
      }

      if (content.product_stores.length > 0) {
        price = content.product_stores[0].price;

        arrStoresState.forEach((storeElement) => {
          if (storeElement.id == content.product_stores[0].store) {
            if (storeElement.image != null) {
              storeURL = storeElement.image.url;
            }
            storeName = storeElement.name;
          }
        });
      }

      arrProductOriginsState.forEach((originElement) => {
        if (originElement.id == content.origin) {
          origin = originElement.name + '製';
        }
      });

      factsCount = content.facts.length;

      if (origin == '') {
        if (factsCount > 0) {
          factFallbackName = content.facts[0].name;
          factFallbackBgColor = content.facts[0].bg_color;
          factFallbackFontColor = content.facts[0].text_color;
          factsCount -= 1;
        }
      }

      if (content.rating) {
        rating = content.rating.rate;

        if (content.rating.total) {
          total = content.rating.total;
        }
      }

      if (content.total) {
        total = content.total;
      }

      if (ratingPercentage < 49) {
        ratingRate += '🤔';
      } else if (ratingPercentage < 79) {
        ratingRate += '👌';
      } else {
        ratingRate += '❤️';
      }

      if (
        contentIndex >= popularFocusedContentPageState * 4 &&
        contentIndex <= popularFocusedContentPageState * 4 + 3
      ) {
        blockPopularContentTmp.push(
          <>
            <div
              className="index-featured-item relative cursor-pointer"
              onClick={() => {
                window.location.href = '/product/' + content.id;
              }}
            >
              <div className="aspect-[1/1] w-full rounded-lg bg-gray-50 p-9">
                <img src={imageURL} alt={imageAlt}></img>
              </div>
              <div className="truncate pt-6 text-sm text-sorra-gray-800">
                {content.brand.name}
              </div>
              <div className="truncate pt-1 text-lg font-medium text-sorra-gray-900">
                {content.name}
                <br />
                {content.en_name}
              </div>
              <div className="truncate pt-1 text-lg font-medium text-sorra-pink">
                {ratingRate} {rating}%
                <span className="font-regular text-base text-sorra-gray-500">
                  {' (' + total + ')'}
                </span>
              </div>
              <div className="flex items-center gap-x-2 pt-2">
                <div
                  className={
                    'index-featured-tag px-2 py-1 text-xs font-medium' +
                    (origin == '' ? ' hidden' : '')
                  }
                >
                  {origin}
                </div>
                <div
                  className={
                    'index-featured-tag px-2 py-1 text-xs font-medium' +
                    (factFallbackName == '' ? ' hidden' : '')
                  }
                  style={{
                    backgroundColor: factFallbackBgColor,
                    color: factFallbackFontColor,
                  }}
                >
                  {factFallbackName}
                </div>
                <div
                  className={
                    'index-featured-tagMore px-2 py-2 text-xs font-medium' +
                    (factsCount <= 0 ? ' hidden' : '')
                  }
                >
                  +{factsCount}
                </div>
              </div>
              <div className={'flex pt-3' + (price == -1 ? ' hidden' : '')}>
                <div className="text-lg font-medium text-sorra-gray-900">
                  HK$ {price} @ {storeURL == '' ? storeName : ''}
                </div>
                {storeURL != '' ? (
                  <img className="h-[28px] pl-1" src={storeURL}></img>
                ) : (
                  ''
                )}
              </div>
              <div
                className={
                  'pt-1 text-sm text-sorra-gray-800' +
                  (content.product_stores.length - 1 <= 0 ? ' hidden' : '')
                }
              >
                還有{content.product_stores.length - 1}間商戶亦有售
              </div>
            </div>
          </>
        );
      }

      blockPopularContentMobileTmp.push(
        <>
          <div
            className="index-featured-item relative cursor-pointer"
            onClick={() => {
              window.location.href = '/product/' + content.id;
            }}
          >
            <div className="aspect-[1/1] w-full rounded-lg bg-gray-50 p-2">
              <img
                src={
                  imageURL == '' ? '/images/placeholders/product.png' : imageURL
                }
                alt={imageAlt}
              ></img>
            </div>
            <div className="truncate pt-6 text-sm text-sorra-gray-800">
              {content.brand.name}
            </div>
            <div className="pt-1 text-sm font-medium text-sorra-gray-900">
              <div className="truncate">{content.name}</div>
              <div className="truncate">{content.en_name}</div>
            </div>
            <div className="flex items-center space-x-1 pt-1">
              <div className="flex items-center text-sm font-bold text-sorra-pink">
                {ratingRate} {rating}%
              </div>
              <div className="text-xs font-medium text-gray-500">{`(${total})`}</div>
            </div>
            <div className="flex items-center gap-x-2 pt-[6px]">
              <div
                className={
                  'index-featured-tag px-2 py-[1px] text-xs font-medium' +
                  (origin == '' ? ' hidden' : '')
                }
              >
                {origin}
              </div>
              <div
                className={
                  'index-featured-tag px-2 py-[1px] text-xs font-medium' +
                  (factFallbackName == '' ? ' hidden' : '')
                }
                style={{
                  backgroundColor: factFallbackBgColor,
                  color: factFallbackFontColor,
                }}
              >
                {factFallbackName}
              </div>
              <div
                className={
                  'index-featured-tagMore px-2 py-[1px] text-xs font-medium' +
                  (factsCount <= 0 ? ' hidden' : '')
                }
              >
                +{factsCount}
              </div>
              <div className="bottom-1 right-0 ml-[auto] text-sm font-bold"></div>
            </div>
            <div className={'flex pt-[6px]' + (price == -1 ? ' hidden' : '')}>
              <div className="text-sm font-medium text-sorra-gray-900">
                HK$ {price} @{' '}
                {storeURL != '' ? (
                  <img className="h-[16px] pl-1" src={storeURL}></img>
                ) : (
                  <div className="text-sm font-medium text-sorra-gray-900">
                    {storeName}
                  </div>
                )}
              </div>
            </div>
            <div
              className={
                'pt-1 text-xs text-sorra-gray-800' +
                (content.product_stores.length - 1 <= 0 ? ' hidden' : '')
              }
            >
              還有{content.product_stores.length - 1}間商戶亦有售
            </div>
          </div>
        </>
      );
    });

    setBlockPopularContentState(blockPopularContentTmp);
    setBlockPopularContentMobileState(blockPopularContentMobileTmp);
  }

  function loadBlockBlog() {
    var blockBlogTmp = [];
    var validBlogCount = 0;

    arrBlogState.forEach(function (element, index) {
      var validBlog = true;
      var imageURL = '';
      var categoryName = '';

      // console.log(element);

      if (element.yoast_head_json.og_image) {
        if (element.yoast_head_json.og_image.length > 0) {
          imageURL = element.yoast_head_json.og_image[0].url;
        } else {
          validBlog = false;
        }
      } else {
        validBlog = false;
      }

      if (element.categories) {
        if (element.categories.length > 0) {
          arrBlogCategoryState.forEach((elementCategory) => {
            element.categories.forEach((loopCateoryID) => {
              if (elementCategory.id == loopCateoryID) {
                categoryName = elementCategory.name;
              }
            });
          });
        }
      }

      if (validBlogCount < 40 && validBlog == true) {
        blockBlogTmp.push(
          // <a className='h-[289px] lg:h-[405px] aspect-[1/1]' href={element.link} target='_blank'>
          <a
            className="aspect-[1/1] w-auto"
            href={element.link}
            target="_blank"
          >
            <img
              className="aspect-[1/1] w-full rounded-xl object-fill"
              src={imageURL}
            ></img>
            <div className="pt-6 text-sm text-sorra-gray-800">
              {categoryName}
            </div>
            <div className="line-clamp-2 pt-1 text-lg font-medium text-sorra-gray-900">
              {element.title.rendered.replaceAll('&amp;', '&')}
            </div>
          </a>
        );

        validBlogCount += 1;
      }
    });

    setBlockBlogState(blockBlogTmp);
  }

  function loadBlockSearchCat() {
    var blockSearchCatTmp = [];

    arrSearchCatState.forEach(function (element, index) {
      blockSearchCatTmp.push(
        <>
          <span>{index > 0 ? ' | ' : ''}</span>
          <a
            href={
              '/search?product=&category=' +
              element.name +
              '&categoryID=' +
              element.id +
              '&keyword='
            }
          >
            {element.name}
          </a>
        </>
      );
    });

    setBlockSearchCatState(blockSearchCatTmp);
  }

  function loadBlockPromotion() {
    var blockPromotionTmp = [];

    arrPromotionsState.forEach(function (element, index) {
      blockPromotionTmp.push(
        <a
          className="flex gap-x-1 text-sm text-sorra-gray-900"
          href={/*element.url*/ 'rewards?tab=code'}
        >
          <img
            src="/svgs/index-partnership-arrow.svg"
            className="mb-[auto] mt-[2px]"
          ></img>
          {element.item_codename}
        </a>
      );
    });

    setBlockPromotionsState(blockPromotionTmp);
  }

  function pressBannerLeft() {
    var bannerFocusedItemTmp = bannerFocusedItemState - 1;

    if (bannerFocusedItemTmp == 1) {
      bannerFocusedItemTmp = 0;
    }

    if (bannerFocusedItemTmp >= 0) {
      setBannerFocusedItemState(bannerFocusedItemTmp);
    }
  }

  function pressBannerRight() {
    var bannerFocusedItemTmp = bannerFocusedItemState + 1;

    if (bannerFocusedItemTmp == 1) {
      bannerFocusedItemTmp = 2; //skip second one in desktop since first and second items is in same page
    }

    if (bannerFocusedItemTmp < arrBannerState.length) {
      setBannerFocusedItemState(bannerFocusedItemTmp);
    }
  }

  function scrollBanner() {
    // var index_banner_container_outer = document.getElementById('index_banner_container_outer');
    // var index_banner_container_outerBox = index_banner_container_outer.getBoundingClientRect()
    // var index_banner_container_outerWidth = index_banner_container_outerBox.width;

    // var index_banner_container_inner = document.getElementById('index_banner_container_inner');
    // var index_banner_container_innerBox = index_banner_container_inner.getBoundingClientRect()
    // var index_banner_container_innerWidth = index_banner_container_innerBox.width;

    // var bannerLeftMarginTmp = bannerFocusedItemState * (419 + 16) * -1;

    // if (bannerFocusedItemState >= 1) {
    //     bannerLeftMarginTmp += (index_banner_container_outerWidth / 2) - (419 / 2) //center focused item
    // }

    // if (index_banner_container_innerWidth + bannerLeftMarginTmp < index_banner_container_outerWidth) {
    //     bannerLeftMarginTmp = (index_banner_container_innerWidth - index_banner_container_outerWidth) * -1;
    // }

    // if (bannerFocusedItemState == 0) {
    //     bannerLeftMarginTmp = 0;
    // }

    // setBannerLeftMarginState(bannerLeftMarginTmp);

    var blockBannerDotsTmp = [];

    var totalPages = arrBannerState.length - 1;

    for (let index = 0; index < totalPages; index++) {
      var isHighlighted = false;

      if (bannerFocusedItemState == 0 || bannerFocusedItemState == 1) {
        if (index == 0) {
          isHighlighted = true;
        }
      } else {
        if (index == bannerFocusedItemState - 1) {
          isHighlighted = true;
        }
      }

      blockBannerDotsTmp.push(
        <div
          className={
            'index-banner-pageDot h-2 w-2 rounded-full bg-black' +
            (isHighlighted == true ? ' highlighted' : '')
          }
        ></div>
      );
    }

    setBlockBannerDotsState(blockBannerDotsTmp);
  }

  function pressFeatureLeft() {
    var featuredFocusedContentPageTmp = featuredFocusedContentPageState - 1;

    if (featuredFocusedContentPageTmp >= 0) {
      setFeaturedFocusedContentPageState(featuredFocusedContentPageTmp);
    }
  }

  function pressFeatureRight() {
    var featuredFocusedContentPageTmp = featuredFocusedContentPageState + 1;

    if (featuredFocusedContentPageTmp < arrFeaturedContentState.length / 4) {
      setFeaturedFocusedContentPageState(featuredFocusedContentPageTmp);
    }
  }

  function pressPopularLeft() {
    var popularFocusedContentPageTmp = popularFocusedContentPageState - 1;

    if (popularFocusedContentPageTmp >= 0) {
      setPopularFocusedContentPageState(popularFocusedContentPageTmp);
    }
  }

  function pressPopularRight() {
    var popularFocusedContentPageTmp = popularFocusedContentPageState + 1;

    if (popularFocusedContentPageTmp < arrPopularContentState.length / 4) {
      setPopularFocusedContentPageState(popularFocusedContentPageTmp);
    }
  }

  function pressSearchProductInputFocus(focused) {
    if (focused == true) {
      window.scroll({ top: 0, behavior: 'smooth' });
    }

    hideSearchHint();

    setSearchBackgroundDisplayState(focused);
    setSearchProductInputFocusState(focused);

    fetchSearch();
  }

  function pressSearchCategoryInputFocus(focused) {
    if (focused == true) {
      window.scroll({ top: 0, behavior: 'smooth' });
    }

    hideSearchHint();

    setSearchBackgroundDisplayState(focused);
    setSearchCategoryInputFocusState(focused);
  }

  function pressSearchKeywordInputFocus(focused) {
    if (focused == true) {
      window.scroll({ top: 0, behavior: 'smooth' });
    }

    hideSearchHint();

    setSearchBackgroundDisplayState(focused);
    setSearchKeywordInputFocusState(focused);
  }

  function pressSearchMobileInputFocus(focused) {
    hideSearchHint();
    setSearchBackgroundDisplayState(focused);
  }

  function pressSearchCategoryMobileExpands(index) {
    var arrSearchCategoryMobileCollapseTmp =
      arrSearchCategoryMobileCollapseState.concat();

    if (arrSearchCategoryMobileCollapseTmp.includes(index)) {
      var index = arrSearchCategoryMobileCollapseTmp.indexOf(index);
      if (index > -1) {
        arrSearchCategoryMobileCollapseTmp.splice(index, 1);
      }
    } else {
      arrSearchCategoryMobileCollapseTmp.push(index);
    }

    setArrSearchCategoryMobileCollapseState(arrSearchCategoryMobileCollapseTmp);
  }

  function hideSearchHint() {
    setSearchProductInputFocusState(false);
    setSearchCategoryInputFocusState(false);
    setSearchKeywordInputFocusState(false);
  }

  function pressSearch() {
    var brandEncoded = '';
    var productEncoded = '';
    var categoryIdEncoded = '';
    var categoryEncoded = '';
    var keywordEncoded = '';

    var queryString = '';
    var logQuery = '';

    brandEncoded =
      'brand=' +
      encodeURIComponent(
        searchBrandKeywordState == null ? '' : searchBrandKeywordState
      );
    productEncoded =
      'product=' +
      encodeURIComponent(
        searchProductKeywordState == null ? '' : searchProductKeywordState
      );
    categoryIdEncoded =
      'categoryID=' + encodeURIComponent(searchCategoryIdState);

    if (searchCategoryIdState != '') {
      categoryEncoded =
        'category=' + encodeURIComponent(searchCategoryKeywordState);
    } else {
      categoryEncoded = 'category=';
    }

    keywordEncoded =
      'keyword=' +
      encodeURIComponent(
        searchKeywordKeywordState == null ? '' : searchKeywordKeywordState
      );

    queryString =
      brandEncoded +
      '&' +
      productEncoded +
      '&' +
      categoryEncoded +
      '&' +
      categoryIdEncoded +
      '&' +
      keywordEncoded;

    logQuery +=
      searchBrandKeywordState != '' && searchBrandKeywordState != 'null'
        ? searchBrandKeywordState + ' '
        : '';
    logQuery += searchProductKeywordState;

    if (logQuery != '' && searchCategoryKeywordState != '') {
      logQuery += ' • ';
    }

    logQuery += searchCategoryKeywordState;

    if (logQuery != '' && searchKeywordKeywordState != '') {
      logQuery += ' • ';
    }

    logQuery += searchKeywordKeywordState;

    MixpanelConfig.track('search_performed', { search: logQuery });

    window.location.href = '/search?' + queryString;
  }

  useEffect(() => {
    appLaunch();
    // fetchHome();
    // fetchBlog();

    const handleScroll = (e) => {
      var scrollTop = e.target.scrollTop;
      // var windowWidth = isNaN(window.innerWidth)
      //   ? window.clientWidth
      //   : window.innerWidth;
      // var windowHeight = isNaN(window.innerHeight)
      //   ? window.clientHeight
      //   : window.innerHeight;

      var index_header = document.getElementById('index_header');
      var index_headerBox = index_header.getBoundingClientRect();
      var index_headerHeight = index_headerBox.height;

      setScrollPositionState(scrollTop / (index_headerHeight - 200));
    };

    if (document.querySelector('#app-wrapper')) {
      document
        .querySelector('#app-wrapper')
        .addEventListener('scroll', handleScroll);
    }

    const queryParameters = new URLSearchParams(window.location.search);
    const search = queryParameters.get('search');
    const product = queryParameters.get('product');
    const category = queryParameters.get('category');
    const categoryID = queryParameters.get('categoryID');
    const keyword = queryParameters.get('keyword');
    const showComment = queryParameters.get('showComment');

    if (search != null) {
      pressSearchProductInputFocus(true);
      setSearchBrandKeywordState('');
      setSearchProductKeywordState(product);
      setSearchCategoryKeywordState(category);
      setSearchCategoryIdState(categoryID);
      setSearchKeywordKeywordState(keyword);
    }

    if (showComment == 'true') {
      setIsCommentModalShow(true);
    }

    window.history.pushState({}, document.title, '/');
  }, []);

  useEffect(() => {
    loadBlockSearchCategory();
  }, [
    arrCategoryState,
    searchCategoryKeywordState,
    arrSearchCategoryMobileCollapseState,
  ]);

  useEffect(() => {
    scrollBanner();
  }, [bannerFocusedItemState]);

  useEffect(() => {
    fetchSearch();
  }, [searchProductKeywordState]);

  useEffect(() => {
    loadBlockSearchProduct();
  }, [arrSearchProductsState, arrSearchBrandtsState]);

  useEffect(() => {
    loadBlockSearchKeyword();
  }, [arrSearchSkinProblemState]);

  useEffect(() => {
    loadBlockBanner();
    scrollBanner();

    loadBlockBannerMobile();
  }, [arrBannerState, fetchingHomeState]);

  useEffect(() => {
    loadBlockComment();
    loadBlockCommentMobile();
  }, [arrBannerState, fetchingHomeState]);

  useEffect(() => {
    var featuredFocusedItemTmp = -1;

    arrFeaturedState.forEach((element) => {
      if (featuredFocusedItemTmp == -1) {
        featuredFocusedItemTmp = element.id;
      }
    });

    setFeaturedFocusedItemState(featuredFocusedItemTmp);

    loadBlockFeatured();
  }, [arrFeaturedState]);

  useEffect(() => {
    arrFeaturedState.forEach((element) => {
      if (element.id == featuredFocusedItemState) {
        if (element.sorra_featured_banner == null) {
          var arrContentTmp = element.products;
          var arrContentMobileTmp = element.products;
        } else {
          var arrContentTmp = [];
          var arrContentMobileTmp = [];

          element.products.forEach(function (product, index) {
            if (index == 0) {
              var banner = element.sorra_featured_banner;
              arrContentMobileTmp.push(banner);
            }

            arrContentTmp.push(product);
            arrContentMobileTmp.push(product);

            if (
              index == 2 ||
              (element.products.length < 3 &&
                index + 1 >= element.products.length)
            ) {
              var banner = element.sorra_featured_banner;
              arrContentTmp.push(banner);
            }
          });
        }

        setArrFeaturedContentState(arrContentTmp);
        setArrFeaturedContentMobileState(arrContentMobileTmp);
      }
    });

    loadBlockFeatured();

    setFeaturedFocusedContentPageState(0);
  }, [featuredFocusedItemState]);

  useEffect(() => {
    loadBlockFeaturedContent();
  }, [arrFeaturedContentState, fetchingHomeState]);

  useEffect(() => {
    loadBlockFeaturedContent();
  }, [featuredFocusedContentPageState]);

  useEffect(() => {
    var popularFocusedItemTmp = -1;

    arrPopularState.forEach((element) => {
      if (popularFocusedItemTmp == -1) {
        popularFocusedItemTmp = element.id;
      }
    });

    setPopularFocusedItemState(popularFocusedItemTmp);

    loadBlockPopular();
  }, [arrPopularState]);

  useEffect(() => {
    arrPopularState.forEach((element) => {
      if (element.id == popularFocusedItemState) {
        var arrContentTmp = element.products;

        setArrPopularContentState(arrContentTmp);
      }
    });

    loadBlockPopular();

    setPopularFocusedContentPageState(0);
  }, [popularFocusedItemState]);

  useEffect(() => {
    loadBlockPopularContent();
  }, [arrPopularContentState]);

  useEffect(() => {
    loadBlockPopularContent();
  }, [popularFocusedContentPageState]);

  useEffect(() => {
    loadBlockBlog();
  }, [arrBlogState]);

  useEffect(() => {
    loadBlockSearchCat();
  }, [arrSearchCatState]);

  useEffect(() => {
    loadBlockPromotion();
  }, [arrPromotionsState]);

  return (
    <div>
      <Head />

      <main>
        <Menubar
          props={{
            homeExtend: true,
            scrollPosition: scrollPositionState,
            notifications: arrNotificationState,
            sourceUI: 'Home',
          }}
          pressSearch={() => {
            pressSearchProductInputFocus(true);
          }}
        />

        <div className="globals-centerContentsMargin disabledTop">
          <div
            className={
              'index-header-search-background hidden lg:block' +
              ((searchBackgroundDisplayState == true &&
                searchProductKeywordState == '' &&
                searchCategoryKeywordState == '' &&
                searchKeywordKeywordState == '') ||
              searchProductInputFocusState == true ||
              searchCategoryInputFocusState == true ||
              searchKeywordInputFocusState == true
                ? ''
                : ' transpirant')
            }
            onClick={() => {
              pressSearchProductInputFocus(false);
              pressSearchCategoryInputFocus(false);
              pressSearchKeywordInputFocus(false);
            }}
          ></div>

          <div
            className={
              "index-header relative bg-[url('/images/bg-header-new_mobile.jpg')] bg-cover bg-center lg:bg-[url('/images/bg-header-new.jpeg')]" +
              (arrNotificationState?.length > 0 ? ' withNoti' : '')
            }
            id="index_header"
          >
            <MaxWidthContainer
              className={
                'index-header-maxWidthContainer relative flex h-full items-start justify-between' +
                (arrNotificationState?.length > 0 ? ' withNoti' : '')
              }
            >
              <div className="index-header-captions relative flex grid w-full grid-rows-1 flex-col items-center justify-center gap-y-2 lg:gap-y-4">
                <p className="text-center text-2xl font-bold lg:text-5xl">
                  Buy Less, Choose Well.
                </p>
                <p className="text-center text-base font-medium lg:text-2xl">
                  最真實美妝評論平台
                </p>
              </div>

              {/* search bar */}
              <div
                className={
                  'index-header-search-frame absolute inset-x-0 bottom-0 z-20 z-[50] hidden px-30 lg:flex' +
                  (scrollPositionState > 1.0 ? ' transpirant' : '')
                }
              >
                <div className="relative flex w-full translate-y-1/2 flex-row items-center rounded-lg bg-white p-4 shadow-lg">
                  <div className="flex h-full grow items-stretch">
                    {/* product */}
                    <div className="relative flex-1">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-6">
                        <Image
                          src="/svgs/search.svg"
                          height={24}
                          width={24}
                          alt="search"
                        />
                      </div>
                      <input
                        type="text"
                        value={searchProductKeywordState}
                        className="block h-14 w-full border-none py-1.5 pl-15 pr-15 text-base text-sorra-gray-900 outline-none placeholder:text-gray-500"
                        placeholder="美妝產品 / 品牌"
                        onFocus={() => pressSearchProductInputFocus(true)}
                        // onBlur={() => pressSearchProductInputFocus(false)}
                        onChange={(event) => {
                          setSearchBrandKeywordState('');
                          setSearchProductKeywordState(event.target.value);
                        }}
                      />
                      <button
                        className="absolute inset-y-0 right-0 flex items-center pr-6 text-sorra-pink"
                        onClick={() => {
                          setSearchBrandKeywordState('');
                          setSearchProductKeywordState('');
                        }}
                      >
                        {searchProductKeywordState != '' ? (
                          <Image
                            src="/svgs/clear.svg"
                            alt="clear"
                            width={14}
                            height={14}
                          />
                        ) : (
                          '全部'
                        )}
                      </button>
                      <div
                        className={
                          'absolute left-[-20px] top-[100px] z-[50] h-[297px] w-[575px] rounded-lg bg-white px-4 py-6' +
                          (searchProductInputFocusState == true
                            ? ''
                            : ' hidden')
                        }
                      >
                        <div className="absolute left-[40px] top-[-8px] h-[20px] w-[20px] rotate-[45deg] bg-white"></div>

                        <div className="h-full w-full space-y-4">
                          {blockSearchProductState}
                        </div>
                      </div>
                    </div>
                    <div className="w-px bg-gray-100" />
                    {/* category */}
                    <div className="relative flex-1">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-6">
                        <Image
                          src="/svgs/grid.svg"
                          height={24}
                          width={24}
                          alt="search"
                        />
                      </div>
                      <input
                        type="text"
                        value={searchCategoryKeywordState}
                        className="block h-14 w-full border-none py-1.5 pl-15 pr-15 text-base text-sorra-gray-900 outline-none placeholder:text-gray-500"
                        placeholder="產品類型"
                        onFocus={() => pressSearchCategoryInputFocus(true)}
                        // onBlur={() => setIsCategoryInputFocus(false)}
                        onChange={(event) => {
                          setSearchCategoryKeywordState(event.target.value);
                          setSearchCategoryIdState('');
                        }}
                      />
                      <button
                        className="absolute inset-y-0 right-0 flex items-center pr-6 text-sorra-pink"
                        onClick={() => {
                          setSearchCategoryKeywordState('');
                          setSearchCategoryIdState('');
                        }}
                      >
                        {searchCategoryKeywordState != '' ? (
                          <Image
                            src="/svgs/clear.svg"
                            alt="clear"
                            width={14}
                            height={14}
                          />
                        ) : (
                          '全部'
                        )}
                      </button>
                      <div
                        className={
                          'absolute left-[-300px] top-[100px] z-[50] h-[345px] w-[1024px] rounded-lg bg-white px-4 pt-6' +
                          (searchCategoryInputFocusState == true
                            ? ''
                            : ' hidden')
                        }
                      >
                        <div className="absolute left-[320px] top-[-8px] h-[20px] w-[20px] rotate-[45deg] bg-white"></div>

                        <div className="h-full w-full space-y-4">
                          {blockSearchCategoryState}
                        </div>
                      </div>
                    </div>
                    <div className="w-px bg-gray-100" />
                    {/* factor */}
                    <div className="relative flex-1">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-6">
                        <Image
                          src="/svgs/sparkles.svg"
                          height={24}
                          width={24}
                          alt="search"
                        />
                      </div>
                      <input
                        type="text"
                        value={searchKeywordKeywordState}
                        className="block h-14 w-full border-none py-1.5 pl-15 pr-15 text-base text-sorra-gray-900 outline-none placeholder:text-gray-500"
                        placeholder="皮膚問題 / 功效 / 關鍵字"
                        onFocus={() => pressSearchKeywordInputFocus(true)}
                        // onBlur={() => setIsFactorInputFocus(false)}
                        onChange={(event) =>
                          setSearchKeywordKeywordState(event.target.value)
                        }
                      />
                      <button
                        className={
                          'absolute inset-y-0 right-0 flex items-center pr-6 text-sorra-pink' +
                          (searchKeywordKeywordState == '' ? ' hidden' : '')
                        }
                        onClick={() => setSearchKeywordKeywordState('')}
                      >
                        <Image
                          src="/svgs/clear.svg"
                          alt="clear"
                          width={14}
                          height={14}
                        />
                      </button>
                      <div
                        className={
                          'absolute left-[-20px] top-[100px] z-[50] h-[180px] w-[450px] rounded-lg bg-white px-4 py-6' +
                          (searchKeywordInputFocusState == true
                            ? ''
                            : ' hidden')
                        }
                      >
                        <div className="absolute left-[40px] top-[-8px] h-[20px] w-[20px] rotate-[45deg] bg-white"></div>

                        <div className="h-full w-full space-y-4">
                          {blockSearchKeywordState}
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    className="flex w-[128px] items-center justify-center gap-x-3 rounded-lg bg-sorra-pink px-6 py-3"
                    onClick={() => pressSearch()}
                  >
                    <Image
                      src="/svgs/search-w.svg"
                      height={20}
                      width={21}
                      alt="search"
                    />
                    <p className="text-sm text-white">搜索</p>
                  </button>
                  <div className="bubble-wrapper absolute inset-x-0 bottom-0 drop-shadow-md">
                    {/* {isProductInputFocus && (
                                        <div className="product-bubble absolute left-0 top-6 h-20 w-1/3 rounded-lg bg-white" />
                                    )}
                                    {isCategoryInputFocus && (
                                        <div className="category-bubble absolute inset-x-0 top-6 h-20 w-full rounded-lg bg-white" />
                                    )}
                                    {isFactorInputFocus && (
                                        <div className="factor-bubble absolute right-0 top-6 h-20 w-2/5 rounded-lg bg-white" />
                                    )} */}
                  </div>
                </div>
              </div>

              <div
                className={
                  'index-header-search-mobile-frame absolute inset-x-0 bottom-4 z-20 flex lg:hidden' +
                  (scrollPositionState > 1.0 ? ' transpirant' : '')
                }
              >
                <div className="mx-4 w-full">
                  <div className="index-header-search-mobile-icon pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <img src="/svgs/search.svg"></img>
                  </div>
                  <input
                    type="text"
                    id=""
                    className="bg-gray-0 index-header-search-mobile block w-full rounded-lg border-none p-2.5 pl-10 text-sm text-sorra-gray-900 focus:border-blue-500 focus:ring-blue-500"
                    placeholder="搜尋美妝產品・話題"
                    onFocus={(event) => {
                      pressSearchMobileInputFocus(true);
                      event.target.blur();
                    }}
                  />
                </div>
              </div>

              <div
                className={
                  'index-header-search-mobile-overlay fixed left-0 top-0 z-[50] flex h-full w-full flex-col' +
                  (searchBackgroundDisplayState == true
                    ? ' block lg:hidden'
                    : ' hidden')
                }
              >
                <div className="index-header-search-mobile-overlay-title relative flex w-full text-lg font-medium text-sorra-gray-900">
                  <button
                    className="border-text-gray-100 absolute left-0 mb-2 mr-2 h-[48px] w-[48px] rounded-lg border p-[14px] text-center text-sm font-medium text-gray-100 focus:outline-none focus:ring-4 focus:ring-blue-300"
                    onClick={() => {
                      pressSearchProductInputFocus(false);
                      pressSearchCategoryInputFocus(false);
                      pressSearchKeywordInputFocus(false);
                    }}
                  >
                    <img
                      src="/svgs/modal-close.svg"
                      className="h-[20px] w-[20px]"
                    ></img>
                  </button>
                  <div className="h-[48px]">搜尋</div>
                </div>
                <div className="grow overflow-x-hidden overflow-y-scroll">
                  <div className="space-y-2 py-[24px]">
                    <div className="relative h-[48px] w-full">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-[16px]">
                        <img src="/svgs/search.svg"></img>
                      </div>
                      <input
                        type="text"
                        id=""
                        className="bg-gray-0 block h-full w-full rounded-lg border border-gray-300 p-2.5 pl-[56px] font-medium text-sorra-gray-900 focus:border-gray-800 focus:ring-0 focus:ring-offset-0"
                        placeholder="美妝產品 / 品牌"
                        value={searchProductKeywordState}
                        onFocus={() => pressSearchProductInputFocus(true)}
                        onChange={(event) => {
                          setSearchBrandKeywordState;
                          setSearchProductKeywordState(event.target.value);
                        }}
                      />
                      <button
                        className="absolute inset-y-0 right-0 flex items-center pr-6 text-sorra-pink"
                        onClick={() => {
                          setSearchBrandKeywordState('');
                          setSearchProductKeywordState('');
                        }}
                      >
                        {searchProductKeywordState != '' ? (
                          <Image
                            src="/svgs/clear.svg"
                            alt="clear"
                            width={14}
                            height={14}
                          />
                        ) : (
                          '全部'
                        )}
                      </button>
                    </div>

                    <div className="relative h-[48px] w-full">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-[16px]">
                        <img src="/svgs/sparkles.svg"></img>
                      </div>
                      <input
                        type="text"
                        id=""
                        className="bg-gray-0 block h-full w-full rounded-lg border border-gray-300 p-2.5 pl-[56px] font-medium text-sorra-gray-900 focus:border-gray-800 focus:ring-0 focus:ring-offset-0"
                        placeholder="產品類型"
                        value={searchCategoryKeywordState}
                        onFocus={() => pressSearchCategoryInputFocus(true)}
                        onChange={(event) => {
                          setSearchCategoryKeywordState(event.target.value);
                          setSearchCategoryIdState('');
                        }}
                      />
                      <button
                        className="absolute inset-y-0 right-0 flex items-center pr-6 text-sorra-pink"
                        onClick={() => {
                          setSearchCategoryKeywordState('');
                          setSearchCategoryIdState('');
                        }}
                      >
                        {searchCategoryKeywordState != '' ? (
                          <Image
                            src="/svgs/clear.svg"
                            alt="clear"
                            width={14}
                            height={14}
                          />
                        ) : (
                          '全部'
                        )}
                      </button>
                    </div>

                    <div className="relative h-[48px] w-full">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-[16px]">
                        <img src="/svgs/grid.svg"></img>
                      </div>
                      <input
                        type="text"
                        id=""
                        className="bg-gray-0 block h-full w-full rounded-lg border border-gray-300 p-2.5 pl-[56px] font-medium text-sorra-gray-900 focus:border-gray-800 focus:ring-0 focus:ring-offset-0"
                        placeholder="皮膚問題 / 功效 / 關鍵字"
                        value={searchKeywordKeywordState}
                        onFocus={() => pressSearchKeywordInputFocus(true)}
                        onChange={(event) =>
                          setSearchKeywordKeywordState(event.target.value)
                        }
                      />
                      <button
                        className="absolute inset-y-0 right-0 flex items-center pr-6 text-sorra-pink"
                        onClick={() => setSearchKeywordKeywordState('')}
                      >
                        {searchKeywordKeywordState != '' ? (
                          <Image
                            src="/svgs/clear.svg"
                            alt="clear"
                            width={14}
                            height={14}
                          />
                        ) : (
                          '全部'
                        )}
                      </button>
                    </div>
                  </div>
                  <div className="ml-[-16px] w-[200%] border-t-2 border-gray-100"></div>
                  <div className="py-[32px]">
                    <div
                      className={
                        'h-full w-full space-y-4' +
                        (searchProductInputFocusState == true ? '' : ' hidden')
                      }
                    >
                      {blockSearchProductMobileState}
                    </div>
                    <div
                      className={
                        'h-full w-full' +
                        (searchCategoryInputFocusState == true ? '' : ' hidden')
                      }
                    >
                      {blockSearchCategoryMobileState}
                    </div>
                    <div
                      className={
                        'h-full w-full' +
                        (searchKeywordInputFocusState == true ? '' : ' hidden')
                      }
                    >
                      {blockSearchKeywordMobileState}
                    </div>
                  </div>
                </div>
                <div className="mt-[32px] h-[48px]">
                  <button
                    className="flex h-full w-full items-center justify-center gap-x-3 rounded-lg bg-sorra-pink px-8 py-3"
                    onClick={() => pressSearch()}
                  >
                    <Image
                      src="/svgs/search-w.svg"
                      height={20}
                      width={21}
                      alt="search"
                    />
                    <p className="text-sm text-white">搜索</p>
                  </button>
                </div>
              </div>
            </MaxWidthContainer>
          </div>

          <div
            className={
              'pt-6 lg:pt-[78px]' +
              (arrBannerState.length == 0 && fetchingHomeState == false
                ? ' hidden'
                : '')
            }
          >
            <MaxWidthContainer>
              <div className="relative block px-4">
                <div className="mx-auto hidden h-[350px] w-[1024px] lg:block">
                  {blockBannerState}
                </div>
                <div className="aspect-[343/193] w-full lg:hidden">
                  {blockBannerMobileState}
                </div>
              </div>
            </MaxWidthContainer>
          </div>

          <div
            className={
              'pt-6 lg:pt-20' +
              (arrFeaturedState.length == 0 && fetchingHomeState == false
                ? ' hidden'
                : '')
            }
          >
            <MaxWidthContainer className="px-4">
              <div
                className={
                  'text-xl font-bold text-sorra-gray-900 lg:text-2xl' +
                  (fetchingHomeState == true ? ' hidden' : '')
                }
              >
                用後感徵集！
              </div>
            </MaxWidthContainer>
            <div className="mt-6 border-b border-gray-100">
              <MaxWidthContainer className="index-category-outer relative">
                <div className="index-category-inner flex space-x-4 px-4 lg:space-x-8 xl:px-0">
                  {blockFeaturedState}
                </div>
              </MaxWidthContainer>
            </div>
            <div className="index-featured-outer relative mt-8 px-4 xl:px-0">
              <MaxWidthContainer className="index-featured-inner relative hidden grid-cols-4 gap-x-6 pr-4 lg:flex">
                <button
                  type="button"
                  className={
                    'index-banner-button-frame group absolute left-0 top-[131px] z-20 hidden h-full cursor-pointer justify-center px-4 focus:outline-none xl:flex' +
                    (featuredFocusedContentPageState <= 0 ? ' transpirant' : '')
                  }
                  onClick={() => pressFeatureLeft()}
                >
                  <div
                    className={
                      'index-banner-button inline-flex h-10 w-10 items-center justify-center rounded-full' +
                      (featuredFocusedContentPageState <= 0
                        ? ' transpirant'
                        : '')
                    }
                  >
                    <img src="/svgs/index-horizontalScroll-arrowLeft.svg"></img>
                  </div>
                </button>

                {blockFeaturedContentState}

                <button
                  type="button"
                  className={
                    'index-banner-button-frame group absolute right-0 top-[131px] z-20 hidden h-full cursor-pointer justify-center px-4 focus:outline-none xl:flex' +
                    (featuredFocusedContentPageState + 1 >=
                    arrFeaturedContentState.length / 4
                      ? ' transpirant'
                      : '')
                  }
                  onClick={() => pressFeatureRight()}
                >
                  <div
                    className={
                      'index-banner-button inline-flex h-10 w-10 items-center justify-center rounded-full' +
                      (featuredFocusedContentPageState + 1 >=
                      arrFeaturedContentState.length / 4
                        ? ' transpirant'
                        : '')
                    }
                  >
                    <img src="/svgs/index-horizontalScroll-arrowRight.svg"></img>
                  </div>
                </button>
              </MaxWidthContainer>

              <MaxWidthContainer className="index-featured-inner flex gap-x-6 pr-4 lg:hidden">
                {blockFeaturedContentMobileState}
              </MaxWidthContainer>
            </div>
          </div>

          <div
            className={
              'pt-6 lg:pt-20' +
              (arrCommentState.length == 0 && fetchingHomeState == false
                ? ' hidden'
                : '')
            }
          >
            <MaxWidthContainer className="px-4">
              <div
                className={
                  'text-xl font-bold text-sorra-gray-900 lg:text-2xl' +
                  (fetchingHomeState == true ? ' hidden' : '')
                }
              >
                近期用後感
              </div>
            </MaxWidthContainer>
            <MaxWidthContainer className="relative mt-[18px] hidden h-[424px] px-4 lg:block">
              {blockCommentState}
            </MaxWidthContainer>

            <div className="index-featured-outer">
              <MaxWidthContainer className="index-featured-inner mt-[18px] flex h-[424px] gap-x-[10px] px-4 lg:hidden">
                {blockCommentMobileState}
              </MaxWidthContainer>
            </div>
          </div>

          <div
            className={
              'pt-6 lg:pt-20' + (arrPopularState.length == 0 ? ' hidden' : '')
            }
          >
            <MaxWidthContainer className="px-4">
              <div className="text-xl font-bold text-sorra-gray-900 lg:text-2xl">
                熱門排行榜
              </div>
            </MaxWidthContainer>
            <div className="mt-6 border-b border-gray-100">
              <MaxWidthContainer className="index-category-outer relative">
                <div className="index-category-inner flex space-x-4 px-4 lg:space-x-8 xl:px-0">
                  {blockPopularState}
                </div>
              </MaxWidthContainer>
            </div>
            <div className="index-featured-outer relative mt-8 px-4 xl:px-0">
              <MaxWidthContainer className="index-featured-inner relative hidden grid-cols-4 gap-x-6 pr-4 lg:flex">
                <button
                  type="button"
                  className={
                    'index-banner-button-frame group absolute left-0 top-[131px] z-20 hidden h-full cursor-pointer justify-center px-4 focus:outline-none xl:flex' +
                    (popularFocusedContentPageState <= 0 ? ' transpirant' : '')
                  }
                  onClick={() => pressPopularLeft()}
                >
                  <div
                    className={
                      'index-banner-button inline-flex h-10 w-10 items-center justify-center rounded-full' +
                      (popularFocusedContentPageState <= 0
                        ? ' transpirant'
                        : '')
                    }
                  >
                    <img src="/svgs/index-horizontalScroll-arrowLeft.svg"></img>
                  </div>
                </button>

                {blockPopularContentState}

                <button
                  type="button"
                  className={
                    'index-banner-button-frame group absolute right-0 top-[131px] z-20 hidden h-full cursor-pointer justify-center px-4 focus:outline-none xl:flex' +
                    (popularFocusedContentPageState + 1 >=
                    arrPopularContentState.length / 4
                      ? ' transpirant'
                      : '')
                  }
                  onClick={() => pressPopularRight()}
                >
                  <div
                    className={
                      'index-banner-button inline-flex h-10 w-10 items-center justify-center rounded-full' +
                      (popularFocusedContentPageState + 1 >=
                      arrPopularContentState.length / 4
                        ? ' transpirant'
                        : '')
                    }
                  >
                    <img src="/svgs/index-horizontalScroll-arrowRight.svg"></img>
                  </div>
                </button>
              </MaxWidthContainer>

              <MaxWidthContainer className="index-featured-inner flex gap-x-6 pr-4 lg:hidden">
                {blockPopularContentMobileState}
              </MaxWidthContainer>
            </div>
          </div>

          <div className="pt-6 lg:pt-20">
            <MaxWidthContainer className="p-4">
              <div className="text-2xl font-bold text-sorra-gray-900">
                社群文章
              </div>
            </MaxWidthContainer>

            <MaxWidthContainer className="grid grid-cols-2 gap-x-6 gap-y-6 px-4 lg:grid-cols-4 lg:gap-y-0 lg:px-0">
              {blockBlogState}
            </MaxWidthContainer>

            <MaxWidthContainer className="flex items-center justify-center p-4 pt-8">
              <a
                type="button"
                className="mb-2 mr-2 w-full rounded-lg border border-sorra-pink px-5 py-2.5 text-center text-sm font-medium text-sorra-pink focus:outline-none focus:ring-4 focus:ring-blue-300 xl:w-[360px]"
                href="https://www.sorra.net/blog/"
              >
                查看全部
              </a>
            </MaxWidthContainer>
          </div>

          <div className="mt-6 bg-[#F5F5F5] lg:mt-20">
            <MaxWidthContainer className="px-4 pb-[36px] pt-[36px] lg:pb-[96px] lg:pt-[96px]">
              <div className="flex items-center justify-center">
                <div className="">
                  <div
                    className="text-center text-2xl font-bold leading-[150%] text-sorra-gray-900 lg:text-5xl"
                    style={{ lineHeight: 150 + '%' }}
                  >
                    2 分鐘完成註冊 即時解鎖會員福利！
                  </div>
                  <div className="mt-[24px] lg:mt-[65px]">
                    <div className="flex w-full flex-wrap place-items-center items-center justify-center gap-x-[34px] gap-y-[40px] lg:gap-x-[94px]">
                      <div className="w-[146px] space-y-[12px] lg:w-[180px] lg:space-y-[24px]">
                        <img
                          className="mx-auto h-[100px] w-[100px] lg:h-[120px] lg:w-[120px]"
                          src="/svgs/loyality-register-00.svg"
                        ></img>
                        <div>
                          <div className="text-center text-base font-normal text-sorra-gray-900 lg:text-xl">
                            第 1 步
                          </div>
                          <div className="text-center text-xl font-bold text-sorra-gray-900 lg:text-2xl">
                            免費註冊
                          </div>
                        </div>
                        <div className="text-center text-base font-normal text-sorra-gray-900 lg:text-xl">
                          所有資料保密，
                          <br />
                          無滋擾聯絡。
                        </div>
                      </div>
                      <div className="w-[146px] space-y-[12px] lg:w-[180px] lg:space-y-[24px]">
                        <img
                          className="mx-auto h-[100px] w-[100px] lg:h-[120px] lg:w-[120px]"
                          src="/svgs/loyality-register-01.svg"
                        ></img>
                        <div>
                          <div className="text-center text-base font-normal text-sorra-gray-900 lg:text-xl">
                            第 2 步
                          </div>
                          <div className="text-center text-xl font-bold text-sorra-gray-900 lg:text-2xl">
                            完成皮膚檔案
                          </div>
                        </div>
                        <div className="text-center text-base font-normal text-sorra-gray-900 lg:text-xl">
                          於會員專區完成
                          <br />
                          「詳細皮膚檔案」
                          <br />
                          獲取額外積分。
                        </div>
                      </div>
                      <div className="w-[146px] space-y-[12px] lg:w-[180px] lg:space-y-[24px]">
                        <img
                          className="mx-auto h-[100px] w-[100px] lg:h-[120px] lg:w-[120px]"
                          src="/svgs/loyality-register-02.svg"
                        ></img>
                        <div>
                          <div className="text-center text-base font-normal text-sorra-gray-900 lg:text-xl">
                            第 3 步
                          </div>
                          <div className="text-center text-xl font-bold text-sorra-gray-900 lg:text-2xl">
                            留評價賺積分
                          </div>
                        </div>
                        <div className="text-center text-base font-normal text-sorra-gray-900 lg:text-xl">
                          分享你的美妝體驗，
                          <br />
                          留評價賺取積分！
                        </div>
                      </div>
                      <div className="w-[146px] space-y-[12px] lg:w-[180px] lg:space-y-[24px]">
                        <img
                          className="mx-auto h-[100px] w-[100px] lg:h-[120px] lg:w-[120px]"
                          src="/svgs/loyality-register-03.svg"
                        ></img>
                        <div>
                          <div className="text-center text-base font-normal text-sorra-gray-900 lg:text-xl">
                            第 4 步
                          </div>
                          <div className="text-center text-xl font-bold text-sorra-gray-900 lg:text-2xl">
                            積分好禮 Get！
                          </div>
                        </div>
                        <div className="text-center text-base font-normal text-sorra-gray-900 lg:text-xl">
                          ✦$1 Sorra 積分兌 HK$1，換取品牌好物。
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-[32px] text-center text-xl font-normal text-sorra-gray-900 lg:mt-[64px]">
                    <button
                      className="h-[48px] w-full rounded-[8px] bg-sorra-pink text-base font-medium text-[#fff] lg:w-[300px]"
                      onClick={() => {
                        MixpanelConfig.track('signinup_from_membership_CTA');
                        window.location.href = '/login';
                      }}
                    >
                      免費註冊
                    </button>
                  </div>
                </div>
              </div>
            </MaxWidthContainer>
          </div>

          <div className="pt-6 lg:pt-20">
            <MaxWidthContainer className="p-4">
              <div className="text-2xl font-bold text-sorra-gray-900">
                合作優惠
              </div>
            </MaxWidthContainer>

            <MaxWidthContainer className="grid grid-cols-2 gap-x-6 gap-y-6 px-4 pt-8 lg:grid-cols-4 lg:px-0">
              {blockPromotionsState}
            </MaxWidthContainer>
          </div>

          <div className="pt-6 lg:pt-20">
            <MaxWidthContainer className="p-4">
              <div className="text-2xl font-bold text-sorra-gray-900">
                搜尋熱門美妝護膚分類
              </div>
            </MaxWidthContainer>

            <MaxWidthContainer className="p-4 text-sm text-sorra-gray-900">
              {blockSearchCatState}
            </MaxWidthContainer>
          </div>

          <div className="pt-6 lg:pt-20">
            <MaxWidthContainer className="p-4">
              <div
                className="cursor-pointer items-center border-b-2 border-t-2 border-gray-100 py-6"
                onClick={() =>
                  setBottomSuggestionsExpanded(
                    bottomSuggestionsExpanded == true ? false : true
                  )
                }
              >
                <div className="flex">
                  <div className="text-2xl font-bold text-sorra-gray-900">
                    化妝護膚品推薦
                  </div>
                  <div className="bottom-1 right-0 ml-[auto] text-sm font-bold">
                    <img
                      src="/svgs/index-section-expand.svg"
                      className={
                        bottomSuggestionsExpanded == true ? 'hidden' : ''
                      }
                    ></img>
                    <img
                      src="/svgs/index-section-collapse.svg"
                      className={
                        bottomSuggestionsExpanded == true ? '' : 'hidden'
                      }
                    ></img>
                  </div>
                </div>

                <div
                  className={
                    'pt-6' +
                    (bottomSuggestionsExpanded == true ? '' : ' hidden')
                  }
                >
                  <Markdown>{bottomSuggestionsMarkdown}</Markdown>
                </div>
              </div>

              <div
                className="cursor-pointer items-center border-b-2 border-gray-100 py-6"
                onClick={() =>
                  setBottomFaqExpanded(bottomFaqExpanded == true ? false : true)
                }
              >
                <div className="flex">
                  <div className="text-2xl font-bold text-sorra-gray-900">
                    常見問題
                  </div>
                  <div className="bottom-1 right-0 ml-[auto] text-sm font-bold">
                    <img
                      src="/svgs/index-section-expand.svg"
                      className={bottomFaqExpanded == true ? 'hidden' : ''}
                    ></img>
                    <img
                      src="/svgs/index-section-collapse.svg"
                      className={bottomFaqExpanded == true ? '' : 'hidden'}
                    ></img>
                  </div>
                </div>

                <div
                  className={
                    'pt-6' + (bottomFaqExpanded == true ? '' : ' hidden')
                  }
                >
                  <Markdown>{bottomFaqMarkdown}</Markdown>
                </div>
              </div>
            </MaxWidthContainer>
          </div>

          <div className="pt-6 lg:pt-24"></div>

          <CommentPopup
            externalPopupShown={isCommentModalShow}
            externalProductID={null}
            externalProductBrandName={null}
            externalProductName={null}
            setExternalPopupShown={setIsCommentModalShow}
          />

          <Footer />

          <MobileTabBar props={{ highlighted: 'home' }} />
        </div>
      </main>
    </div>
  );
}

export async function getServerSideProps(ctx) {
  // let props = await getInitProps(ctx);

  try {
  } catch (e) {
    return {
      notFound: true,
    };
  }
  return {
    props: {},
  };
}
